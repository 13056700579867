import Irene from "../utils/images/silueta_irene.png";
import David from "../utils/images/silueta_david.png";
import Paula from "../utils/images/silueta_paula.png";
import Elena from "../utils/images/silueta_elena.png";

export const agentes = [
  {
    id: 1,
    nombre: "Irina Strof",
    profesion: "Médico",
    imagen: Irene,
    cordura: 10,
    estabilidad: 12,
    salud: 12,
    hab_inv: {
      Antropología: 1,
      Arqueología: 1,
      Biología: 4,
      Contabilidad: 2,
      Física: 1,
      Latín: 2,
      Medicina: 4,
      Consuelo: 4,
      Crédito: 4,
      Farmacología: 4,
      Medicina_Forense: 4,
      Química: 1
    },
    hab_gen: {
      Armas_de_Fuego: 5,
      Atletismo: 5,
      Huida: 12,
      Primeros_Auxilios: 12,
      Sigilo: 6
    }
  },
  {
    id: 2,
    nombre: "David Strong",
    profesion: "Detective",
    imagen: David,
    cordura: 8,
    estabilidad: 10,
    salud: 12,
    hab_inv: {
      Criptografía: 1,
      Derecho: 2,
      Crédito: 4,
      Evaluar_Sinceridad: 2,
      Interrogatorio: 4,
      Jerga_Policial: 4,
      Cerrajería: 2,
      Farmacología: 1,
      fotografia: 1,
      Habilidad_Artesanal: 1,
      Química: 1,
      Recogida_de_Pruebas: 1,
      Supervivencia: 1
    },
    hab_gen: {
      Armas_de_Fuego: 12,
      Atletismo: 12,
      Huida: 8,
      Ocultar: 5,
      Sigilo: 5,
      Sentir_el_Peligro: 12
    }
  },
  {
    id: 3,
    nombre: "Abby Danger",
    profesion: "Periodista",
    imagen: Paula,
    cordura: 10,
    estabilidad: 12,
    salud: 12,
    hab_inv: {
      Ciencias_Ocultas: 1,
      Teología: 2,
      Crédito: 3,
      Burocracia: 1,
      Consuelo: 2,
      Evaluar_Sinceridad: 4,
      Historia_Oral: 2,
      Jerga_Policial: 2,
      Regatear: 1,
      Astronomía: 4,
      Fotografía: 2,
      Recogida_de_Pruebas: 4
    },
    hab_gen: {
      Armas: 5,
      Atletismo: 4,
      Huida: 10,
      Disfráz: 8,
      Seguir: 8,
      Psicoanálisis: 7
    }
  },
  {
    id: 4,
    nombre: "Jian Li",
    profesion: "Anticuario",
    imagen: Elena,
    cordura: 10,
    estabilidad: 12,
    salud: 12,
    hab_inv: {
      Arqueología: 1,
      Arquitectura: 2,
      Buscar_Libros: 4,
      Ciencias_Ocultas: 2,
      Criptografía: 4,
      Historia: 4,
      Historia_del_Arte: 2,
      Idiomas: 2,
      Crédito: 2,
      Regatear: 2,
      Cerrajería: 1,
      Supervivencia: 1
    },
    hab_gen: {
      Armas: 6,
      Electricidad: 8,
      Primeros_Auxilios: 8,
      Sentir_Peligro: 8,
      Sigilo: 6
    }
  }
];
export const habilidadesGenerales = 
	{
		Armas: "Estás entrenado en el uso de armas de mano como cuchillos, espadas o látigos. El uso de porras, nudillos de hierro, cachiporras o rollos de monedas forma parte de Escaramuza.",
		Armas_de_Fuego: "Se te dan bien las armas de fuego, incluyendo su reparación y su identificación. Esta habilidad también abarca ballestas y otras armas de proyectiles con gatillo.",
		Atletismo: "Esta habilidad te permite realizar proezas físicas en general, desde correr hasta saltar pasando por lanzar cartuchos de dinamita y esquivar objetos. Esta habilidad abarca cualquier acción física no cubierta por otra habilidad.",
		Birlar: "Tus hábiles dedos te permiten manipular objetos pequeños con gran destreza.",
		Conducción: "Cualquiera que haya aprendido a conducir puede manejar un coche sin necesidad de esta habilidad. Tú, sin embargo, eres un habilidoso conductor defensivo, capaz de obtener unas buenas prestaciones de cualquier tipo de automóvil, camión u ómnibus. Por cada 2 puntos que poseas en Conducción puedes elegir un tipo de vehículo terrestre adicional: motocicletas, camiones de transporte, locomotoras, diligencias o tranvías. También puedes elegir vehículos más exóticos, como tanques y cabriolés. Puedes elegirlos al crear el investigador o hacerlo durante el juego.",
		Cordura: "Es tu salud mental a largo plazo. Indica hasta cuánto eres capaz de mantener la fe en cualquier tipo de preocupación humana fundamental.",
		Disfraz: "Esta es la capacidad de alterar tu propia apariencia, pose y voz para hacerte irreconocible. Disfrazar a otros sólo funciona durante cortos periodos de tiempo. Disfrazarte con éxito de una persona conocida ante aquellos con quienes vas a interactuar es extraordinariamente difícil. Imitar brevemente la voz tiene un nivel de dificultad de 4. Suplantar a esa persona en un encuentro cara a cara tiene un nivel de dificultad de 7 y exige hacer un control cada cinco minutos que mantengas el contacto.",
		Electricidad: "Eres bueno construyendo, arreglando, manejando y desmontando dispositivos eléctricos, desde sistemas de alarma sencillos hasta las radios más avanzadas. Con las piezas adecuadas, puedes crear aparatos improvisados a partir de restos de chatarra.",
		Escaramuza: "Te desenvuelves bien en una pelea, ya sea con la intención de matar, noquear, apresar o evitar a tu oponente. Una puntuación elevada en esta habilidad puede indicar cierto tipo de entrenamiento, mucha experiencia en peleas de bar o un gancho de derecha devastador. Esta habilidad cubre el uso de porras, nudillos de hierro, cachiporras o rollos de monedas.",
		Estabilidad: "Es tu salud mental a corto plazo, indica tu resistencia al trauma mental.",
		Explosivos: "Eres un experto en bombas y trampas explosivas.",
		Hipnosis: "sólo Pulp esta habilidad representa la hipnosis médica; no es mesmerismo - psíquico ni control mental. Sólo puedes hipnotizar a un sujeto voluntario, y sólo a un sujeto cada vez. El nivel de dificultad de los controles de Hipnosis varía dependiendo de la finalidad de la sesión.",
		Huida: "Se te da muy bien salir corriendo sin que por ello seas apto en otras tareas físicas. Esta habilidad se puede emplear en sustitución de Atletismo cuando se esté escapando durante una persecución. No puede emplearse cuando se es perseguidor, sólo el perseguido.",
		Mecánica: "Sabes Construir, reparar e inutilizar mecanismos, desde simples trampas de palos hasta las máquinas de calcular simples trampas de palos hasta máquinas de calcular o turbinas a vapor. Con los componentes adecuados a mano puedes crear aparatos improvisados desde cero. Por cada punto que poseas en Mecánica puedes manejar y, cuando sea relevante, maquinaria pesada, como: tanques. excavadoras, grúas de construcción o palas a vapor. Puedes elegir manejar tipos de maquinaria más exóticos, como motores a vapor de barcos transoceánicos o artillería pesada. Puedes elegirlos al crear el investigador o hacerlo durante el juego.",
		Montar: "Eres un jinete consumado. Puedes galopar al caballo más fogoso por cualquier tipo de terreno o dominar a los burros y mulas más recalcitrantes. Por cada 2 puntos que poseas en Monta puedes elegir un tipo de animal de monta como caballo, camello búfalo de agua o elefante. Puedes elegirlos al crear el investigador o hacerlo durante el juego.",
		Ocultar: "Puedes esconder objetos y evitar que los encuentren durante	un registro. Tus métodos pueden incluir el camuflaje, ocultar los objetos en tu persona, deslizarlos en un cajón, construir compartimentos secretos o alterar su aspecto utilizando pintura o yeso. Esta habilidad también te permite encontrar objetos escondidos de forma intencionada.",
		Pilotaje: "Sabes pilotar naves pequeñas o aeroplanos ligeros de un otor. Una puntuación de 1 permite seleccionar botes pequeños o aeroplanos ligeros de un motor. Por cada 2 puntos adicionales puedes añadir otro tipo de vehículo a tu repertorio: botes pequeños, aeroplanos ligeros de un motor, planeadores, hidroaviones, aeroplanos de varios motores, yates y barcos de varios mástiles, así como otros tipos más exóticos, como dirigibles, submarinos y autogiros. Puedes elegirlos al crear el investigador o hacerlo durante el juego.",
		Preparación: "Anticipas las necesidades de cualquier misión preparando un equipaje con todo lo necesario. Si tienes acceso a tu equipaje, puedes intentar superar un control con un nivel de dificultad de 4 para obtener cualquier objeto que el grupo necesite para superar un obstáculo. Esto se puede hacer durante la aventura, en el momento en que surja la necesidad. No hace falta hacer un control en el caso de objetos de utilidad obvia en una investigación de los Mitos (material de escritura, linternas, velas y cerillas, herramientas comunes, etc.). Otras habilidades implican la posesión de cierto equipo básico necesario para poder desempeñarlas. Los investigadores que posean las habilidades de Primeros Auxilios o medicina tendrán botiquines de primeros auxilios, aquellos con Fotografía llevarán cámaras, carretes y flashes, si poseen Armas de Fuego tendrán una pistola, etc. El tipo de objetos que puedes tener disponible en un momento dado no depende de tu puntuación en la habilidad, sino de lo creíble que resulte para la narración, a discreción del Guardián.",
		Primeros_Auxilios: "Puedes realizar los primeros auxilios a individuos enfermos o heridos.",
		Psicoanálisis: "Puedes proporcionar consuelo, perspectiva y solaz a personas con problemas mentales. Puedes hacer que un investigador en estado de pánico recupere la calma, recuperar puntos perdidos de la reserva de Estabilidad.",
		Salud: "Es tu capacidad para soportar heridas, resistir infecciones y sobrevivir al efecto de las toxinas. Cuanto mayor sea tu reserva de Salud más tiempo aguantarás peleando antes de sucumbir a tus heridas.",
		Seguir: "Eres bueno siguiendo a un sospechoso sin revelar tu presencia.",
		Sentir_el_Peligro: "Esta habilidad permite percibir peligros potenciales, para ti o para otros. El Guardián no debe comunicar la dificultad de un control de Sentir el Peligro antes de que el jugador decida cuántos puntos gastar. El Guardián no tira en secreto, por lo que incluso una tirada fallida permitirá sentir que algo anda mal. Simplemente no sabrán de qué se trata.",
		Sigilo: "Se te da bien esconderte y moverte sin ser visto. Usa Sigilo cuando quieras moverte por un lugar sin ser detectado; si pretendes esconderte para despistar a un perseguidor, utiliza Seguir (para huir de él a la carrera será necesario usar Atletismo o Huida)."
	}
;

export const habilidadesInvestigacionAcademicas = 
   {
    Antropología: "Eres un experto en el estudio de las culturas humanas desde la edad de piedra hasta la edad del jazz. La antropología física cae dentro de Medicina Forense.",
    Arqueología: "Excavas y estudias las estructuras y artefactos de culturas y civilizaciones históricas.",
    Arquitectura: "Sabes cómo se diseñan y construyen los edificios.",
    Biología: "Estudias la evolución el comportamiento y la apariencia física de los organismos vivos.",
    Buscar_libros: "Sabes cómo extraer información de colecciones de libros, registros, archivos, hemerotecas o grandes pilas de correspondencia o telegramas desordenados. También puedes detectar patrones en los datos.",
    Ciencias_ocultas: "Eres un experto en el estudio histórico de la magia, las supersticiones y la práctica hermética, desde la edad de piedra hasta el presente. Esta habilidad no te permite hacer magia ni invocar entidades sobrenaturales.",
    Contabilidad: "Entiendes los procedimientos de la contabilidad y puedes leer y mantener registros financieros.",
    Criptografía: "Eres un experto creando y descifrando códigos en cualquier idioma que seas capaz de leer. Con algo de tiempo y un diccionario, puedes llegar a deducir alfabetos extranjeros.",
    Derecho: "Estás familiarizado con las leyes civiles y criminales de tu jurisdicción local y tienes conocimientos generales sobre los sistemas legales del extranjero. Si tienes una puntuación de 2+ puede ser un abogado colegiado.",
    Física: "Estudias las fuerzas fundamentales del universo: presión, electromagnetismo, movimiento, gravedad, óptica y radioactividad.",
    Geología: "Eres un experto en rocas, tierras, minerales y en la historia primordial de la Tierra.",
    Historia:	"Eres experto en la historia humana registrada, con énfasis en los avances políticos, militares, económicos y tecnológicos; así como en el empleo de las herramientas de los historiadores: libros y documentos.",
    Historia_del_Arte: "Eres un experto en obras de arte, incluyendo las artes prácticas como ebanistería y alfarería, desde un punto de vista técnico y estético.",
    Idiomas: "Por cada punto que poseas en Idiomas, sabes hablar y escribir de modo fluido un idioma distinto a tu lengua materna o una lengua muerta. Puedes especificar estas lenguas al crear el personaje o elegirlas durante el juego. Anota estos idiomas en los espacios que hay bajo la habilidad de Idiomas en la hoja de personaje. Algunas profesiones especifican cuáles son estos idiomas.",
    Medicina: "Puedes diagnosticar y tratar enfermedades humanas, heridas, envenenamientos y el deterioro físico, y puede que también tengas conocimientos de medicina veterinaria. Si tienes una puntuación de 2+ puedes tener una licencia médica.",
    Mitos_de_Cthulhu: "Has empezado a hacer encajar las reglas secretas del mundo real, viendo a través de los velos de la física y la religión. Reconoces los grandes nombres y las verdades que esconden. Si has leído un tomo de los Mitos, emplear esta habilidad te permitirá recordar cualquier pista o hecho relevante para tu situación actual. Si estás observando un antiguo bajorrelieve alienígena, te permitirá percibir la terrible historia que narra en un súbito momento de iluminación. La finalidad del uso de esta habilidad durante una investigación es alcanzar una epifanía horrorosa. El Guardián te proporcionará el resultado de tu intuición, esbozando las implicaciones de los Mitos en los eventos que has descubierto. Emplear esta habilidad cuesta Cordura y Estabilidad.",
    Teología: "Estudias las religiones humanas en todas sus variantes, tanto antiguas como modernas. Esta habilidad no permite obrar milagros, desterrar demonios, comulgar con deidades ni invocar ningún tipo de poder sobrenatural. Si tienes puntuación tanto en Teología como en Mitos de Cthulhu podrás determinar si una ceremonia es un ritual de los Mitos. Esto se considera un empleo de la habilidad de Teología y no tendrá coste alguno de Cordura ni Estabilidad."
}

export const habilidadesInvestigacionInterpersonales = 
{
    Adulación: "Eres bueno consiguiendo que la gente te ayude, por medio de cumplidos sutiles o directos. Esto funciona especialmente bien con sujetos que te encuentren atractivo, pero no es algo estrictamente necesario. Tú decides si tu puntuación en Adulación significa que eres físicamente atrayente, carismático y encantador, o servil.",
    Bajos_Fondos: "Sabes cómo comportarte entre bandidos, violadores, drogadictos, prostitutas y otros habituales del submundo criminal.",
    Burocracia: "Sabes cómo desenvolverte en una organización burocrática, ya se trate de una oficina gubernamental o una gran empre empresa. Sabes obtener lo que quieres de ella rápidamente y con la mínima irritación.",
    Consuelo: "Puedes conseguir que la gente haga lo que quieres tranquilizándola y reconfortándola.",
    Crédito: "Eres capaz de operar fácilmente dentro de tu clase social: efectuar compras, mezclarte socialmente, celebrar recepciones, enterarte de rumores, pedir favores de profesionales o de contactos sociales, o conseguir un préstamo. La puntuación de Crédito indica tanto tu clase social como el tamaño de tu reserva.",
    Evaluar_sinceridad: "Puedes determinar cuándo miente la gente y, efectuando un gasto, hacer una suposición acertada sobre sus motivos. No todas las mentiras son verbales, puedes notar cuándo una persona está intentando generar una impresión falsa por medio de su lenguaje corporal. Ten en cuenta que algunas personas pueden mentir tan bien que sea imposible detectarlo o que podrían hacer parecer que todo lo que dicen es mentira, y también los hay que se creen sus propias mentiras. También puedes emplear Evaluar Sinceridad para detectar timos de videntes, falsas sesiones de espiritismo o mentalismo y similares.",
    Historia_Oral: "Puedes encontrar gente dispuesta a hablar, ganarte su confianza, y conseguir detallados testimonios orales acerca de eventos históricos, tradiciones locales, folclore, leyendas familiares o rumores.",
    Interrogatorio: "Estás entrenado para extraer información de sospechosos y testigos en el contexto formal de una entrevista de estilo policial. Ésta debe tener lugar en un entorno oficial, donde el sujeto esté confinado o se sienta bajo la amenaza de ser confinado, y donde reconozca tu autoridad (sea real o fingida).",
    Intimidación: "Eres capaz de obtener la cooperación de un sospechoso apelando a tu fuerza física, invadiendo su espacio personal y adoptando una actitud psicológicamente dominante. La intimidación puede implicar amenazas sutiles o directas de violencia física, pero a menudo se trata tan sólo de un acto de dominación mental.",
    Jerga_Policial: "Sabes hablar en la jerga de los oficiales de policía y hacer que se sientan tranquilos y confiados en tu presencia.",
    Regatear: "Eres experto en cerrar tratos con otros, convenciéndoles de que el mejor arreglo para ti es también el mejor para ellos."
}
    
export const habilidadesInvestigacionTécnicas = 
	{
    Astronomía: "Estudias los cuerpos celestes, como estrellas y planetas.",
    Cerrajería: "Puedes abrir puertas y cerraduras y desactivar alarmas, sin poseer la llave. Muchas cerraduras requieren herramientas especializadas cuya posesión sin una licencia. podría constituir Puede que se necesite efectuar un gasto para abrir una cerradura, rápidamente, sin hacer ruido, sin dañarla o para cerraduras muy complejas o difíciles. Será necesario superar un control para abrir aquellas cerraduras que sirvan para generar tensión o conflicto en vez de para contener pistas.",
    Farmacología: "Eres capaz de identificar y preparar drogas y medicinas.",
    Fotografía: "Sabes manejar cámaras tanto de imagen fija como de imagen en movimiento.",
    Habilidad_Artesanal: "Puedes crear objetos físicos de utilidad a partir de materiales como madera, metal, joyas y similares. Aunque pueden llegar a ser bellos te centras en la utilidad, no en lo artístico. Puedes centrarte un tipo de artesanía o diversificarte. Por cada punto que poseas en Habilidad Artesanal puedes elegir un tipo de artesanía que dominas. Puedes hacerlo al crear el investigador o elegirlas durante el juego.",
    Habilidad_Artística: "Eres capaz de crear algún tipo de arte: música, pintura, baile, escultura, canción, poesía, teatro y similares. Como ocurre con Habilidad Artesanal, puedes elegir centrarte en un arte o diversificarte; se aplican las mismas reglas. Para crear una obra de arte notable será necesario efectuar un gasto.",
    Medicina_Forense: "Estudias la escena del crimen y realizas autopsias para determinar la causa y circunstancias de una muerte. Puedes emplear huesos para reconstruir los detalles físicos del cadáver, tales como edad, sexo, condición médica y, a veces, profesión. PULP: puedes determinar la hora, y a veces el lugar, de la muerte estudiando los insectos presentes en la escena, o los huevos y larvas presentes en el cuerpo.",
    Química: "Estás entrenado en el análisis de sustancias químicas.",
    Recogida_de_Pruebas: "Eres bueno encontrando, almacenando y etiquetando pruebas importantes.",
    Supervivencia: "Estás acostumbrado a trabajar y vivir al aire libre y en la naturaleza."
}
