import firebase from 'firebase';
const config = {
  apiKey: "AIzaSyDHF3sD7de5ePtS0BnCDO87tJ1nEsAJgFE",
  authDomain: "rastro-256720.firebaseapp.com",
  databaseURL: "https://rastro-256720.firebaseio.com",
  projectId: "rastro-256720",
  storageBucket: "rastro-256720.appspot.com",
  messagingSenderId: "286900852622",
  appId: "1:286900852622:web:f8cb1e0dc8d41f99ddf6df"
};
firebase.initializeApp(config);
export default firebase;
