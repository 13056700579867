import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./estilos/estilos.css";


// import Login from './components/Login';

ReactDOM.render(<App />, document.querySelector("#root"));
