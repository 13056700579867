import React from "react";
import Header from "./Header";
import Diario from "./Diario";
import Footer from "./Footer";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AgentesContededor from "./AgentesContenedor";
import HabilidadesContenedor from "./HabilidadesContenedor";
import { agentes } from "../utils/datos";
import Agente from "./Agentes/AgentePage";
import { MDBContainer } from "mdbreact";

const mainContainer = () => {
  return (
    <MDBContainer className="fondoGeneral">
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Diario} />
          <Route exact path="/Diario" component={Diario} />
          <Route exact path="/Agentes" component={AgentesContededor} />
          <Route
            exact
            path="/Agentes/AgentePage/1"
            render={props => <Agente {...props} agente={agentes[0]} />}
          />
          <Route
            exact
            path="/Agentes/AgentePage/2"
            render={props => <Agente {...props} agente={agentes[1]} />}
          />
          <Route
            exact
            path="/Agentes/AgentePage/3"
            render={props => <Agente {...props} agente={agentes[2]} />}
          />
          <Route
            exact
            path="/Agentes/AgentePage/4"
            render={props => <Agente {...props} agente={agentes[3]} />}
          />
          <Route exact path="/Habilidades" component={HabilidadesContenedor} />
        </Switch>
        {/* <Footer /> */}
      </BrowserRouter>
    </MDBContainer>
  );
};

export default mainContainer;
