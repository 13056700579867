import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import { getHabilidades } from '../../utils/usefullFunctions';
const AgentePage = props => {
  const agent = props.agente;
  const habInvestigacion = agent.hab_inv;
  const habGenerales = agent.hab_gen;

  /* function habInv(h) {
    return Object.keys(h).map(habilidad => (
      <ul key={habilidad}>
        <li>
          {habilidad} : {h[habilidad]}
        </li>
      </ul>
    ));
  } */

  /*  const habGen = Object.keys(habGenerales).map(habilidad => (
    <ul>
      <li>
        {habilidad} : {habGenerales[habilidad]}
      </li>
    </ul>
  )); */

  return (
    <>
      <MDBRow className="mt-4">
        <MDBCol md="4">
          <img
            src={agent.imagen}
            className="img-fluid imagenPage mx-auto d-block"
            alt="Imagen"
          />
        </MDBCol>
        <MDBCol principalNombremd="6">
          <span className="principalNombre">Nombre:</span>
          <span className="principalValor">{agent.nombre}</span> <br />
          <span className="principalNombre">Profesión:</span>
          <span className="principalValor">{agent.profesion}</span>
          <br />
          <span className="principalNombre">Pilares:</span>
          <br />
          <span className="principalNombre">Salud: </span>
          <span className="principalValor">{agent.salud}</span>
          <br />
          <span className="principalNombre">Cordura: </span>
          <span className="principalValor">{agent.cordura}</span>
          <br />
          <span className="principalNombre">Estabilidad: </span>
          <span className="principalValor">{agent.estabilidad}</span>
          <br />
        </MDBCol>
      </MDBRow>
      <MDBContainer>
        <MDBRow className="mt-2">
          <h1>Habilidades de Investigación</h1>
        </MDBRow>
        <MDBRow className="mt-2">
          {getHabilidades(habInvestigacion)}
        </MDBRow>
        <MDBRow className="mt-2">
          <h1>Habilidades Generales</h1>
        </MDBRow> <MDBRow className="mt-2">
          {getHabilidades(habGenerales)}
        </MDBRow>
      </MDBContainer>
    </>
  );
};

export default AgentePage;
