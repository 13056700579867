import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

const Diario = () => {
  return (
    <MDBRow>
      <MDBCol></MDBCol>
      <MDBCol size="11">
        <MDBRow>
          <MDBCol className="text-center tituloDiario">
            <h2 className="decorado">Día 1</h2>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol className="text-justify">
            <p>
              Primero Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Praesent a ultrices justo. Aliquam fringilla nulla neque. Mauris
              auctor vestibulum dui, vel sollicitudin purus lacinia at. Quisque
              egestas sem lorem. Curabitur mollis justo vel felis cursus, vitae
              facilisis metus accumsan. Sed iaculis arcu non ullamcorper
              molestie. Maecenas eu euismod enim. Phasellus rhoncus, orci sed
              molestie tincidunt, elit orci lacinia tortor, eget consequat risus
              elit ullamcorper purus. Phasellus consectetur sit amet diam in
              molestie.
            </p>
            <p>
              Segundo Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Praesent a ultrices justo. Aliquam fringilla nulla neque. Mauris
              auctor vestibulum dui, vel sollicitudin purus lacinia at. Quisque
              egestas sem lorem. Curabitur mollis justo vel felis cursus, vitae
              facilisis metus accumsan. Sed iaculis arcu non ullamcorper
              molestie. Maecenas eu euismod enim. Phasellus rhoncus, orci sed
              molestie tincidunt, elit orci lacinia tortor, eget consequat risus
              elit ullamcorper purus. Phasellus consectetur sit amet diam in
              molestie.
            </p>
            <p>
              Tercero Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Praesent a ultrices justo. Aliquam fringilla nulla neque. Mauris
              auctor vestibulum dui, vel sollicitudin purus lacinia at. Quisque
              egestas sem lorem. Curabitur mollis justo vel felis cursus, vitae
              facilisis metus accumsan. Sed iaculis arcu non ullamcorper
              molestie. Maecenas eu euismod enim. Phasellus rhoncus, orci sed
              molestie tincidunt, elit orci lacinia tortor, eget consequat risus
              elit ullamcorper purus. Phasellus consectetur sit amet diam in
              molestie.
            </p>
          </MDBCol>
        </MDBRow>
      </MDBCol>
      <MDBCol></MDBCol>
    </MDBRow>
  );
};

export default Diario;
