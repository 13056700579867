import React from "react";
import {
  MDBMask,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput
} from "mdbreact";
import firebase from '../firebase';

class Login extends React.Component {
  state = {
    authenticated: false,
    email: "",
    password: "",
    error:""
  };

  handleChangeInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        this.props.history.push('/');
      })
      .catch((error) => {
        this.setState({ error: error });
      });
    console.log(this.state);
  };

  render() {
    const { email, password} = this.state;
    return (
      <div id="classicformpage">
        <MDBView>
          <MDBMask className="d-flex justify-content-center align-items-center gradient">
            <MDBContainer>
              <MDBRow center>
                <MDBCol md="4" xl="5" className="mb-4">
                  <form onSubmit={this.handleSubmit}>
                    <MDBCard id="classic-card">
                      <MDBCardBody className="white-text">
                        <h3 className="text-center">Identificación</h3>
                        <hr className="hr-light" />
                      <MDBInput
                        type="email"
                        name="email"
                        value={email}
                          onChange={this.handleChangeInputChange}
                        className="white-text"
                        iconClass="white-text"
                        label="Correo electrónico"
                        icon="user"
                      />
                      <MDBInput
                        value={password}
                          onChange={this.handleChangeInputChange}
                        className="white-text"
                        iconClass="white-text"
                        label="Contraseña"
                        icon="lock"
                        type="password"
                        name="password"
                        />
                        <div className="text-center mt-4 black-text">
                          <MDBBtn color="indigo" type="submit">
                            Acceso
                          </MDBBtn>
                        </div>
                      </MDBCardBody>
                    </MDBCard>
                  </form>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </div>
    );
  }
}

export default Login;
