import React from 'react';

//paso un array de habilidades y extraigo nombre y valor
export function getHabilidades(habilidades) {
  return Object.keys(habilidades).map(habilidad => (
    <ul key={habilidad}>
      <li>
        {habilidad} : {habilidades[habilidad]}
      </li>
    </ul>
  ));
}

//Tabla de habilidades pasada un objeto se obtienen campo y valor en una tabla
export function getHabilidadesTabla(habilidades) {
  return Object.keys(habilidades).map(habilidad => (
    <tr key={habilidad}>
        <td>{habilidad}</td>
        <td className="text-justify">{habilidades[habilidad]}</td>
    </tr>
  ));
}
