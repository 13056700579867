import React from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText, MDBCol } from 'mdbreact';
import { Link } from 'react-router-dom';

const AgenteCard = (props) => {
  return (
    <div>
      <MDBCol>
        <MDBCard style={{ width: "15rem" }}>
          <MDBCardImage className="rounded mx-auto d-block" src={props.imagen} waves />
          <MDBCardBody>
            <MDBCardTitle>{props.nombre}</MDBCardTitle>
            <MDBCardText>
              Profesión: {props.profesion}  
            </MDBCardText>
            {/* <MDBBtn color="#f3e5f5 purple lighten-5" href="#">Profile</MDBBtn>  ////<Link to={`/Agentes/AgentePage/${props.id}`/> */}
            <Link to={`/Agentes/AgentePage/${props.id}`}>Profile</Link> 
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </div>
  );
}

export default AgenteCard;