import React, { Component } from "react";
import Logo from "../utils/images/logo_trans_cua.png";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer
} from "mdbreact";
import Logout from "./Logout";

class Header extends Component {
  state = {
    isOpen: false
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  style = {
    color: "black"
  };

  render() {
    return (
      <MDBContainer>
        <MDBNavbar color="trasparent" dark expand="md">
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav left>
              <MDBNavItem active>
                <MDBNavLink
                  className="fuenteEnlaces"
                  style={this.style}
                  to="/Diario"
                >
                  Diario
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  className="fuenteEnlaces"
                  style={this.style}
                  to="/Agentes"
                >
                  Agentes
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink
                  className="fuenteEnlaces"
                  style={this.style}
                  to="/Habilidades"
                >
                  Habilidades
                </MDBNavLink>
              </MDBNavItem>
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <MDBNavItem>
                <img src={Logo} alt="logo" />
              </MDBNavItem>
              <MDBNavItem>
                <Logout />
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </MDBContainer>
    );
  }
}

export default Header;
