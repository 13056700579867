import React from 'react';
import {
  habilidadesGenerales, habilidadesInvestigacionAcademicas,
  habilidadesInvestigacionInterpersonales, habilidadesInvestigacionTécnicas
} from '../utils/datos';
import { getHabilidadesTabla } from '../utils/usefullFunctions';
import {
  MDBContainer,
  MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead
} from "mdbreact";

const HabilidadesContenedor = () => {
  const habGen = habilidadesGenerales;
  const habAcad = habilidadesInvestigacionAcademicas;
  const habInter = habilidadesInvestigacionInterpersonales;
  const habTec = habilidadesInvestigacionTécnicas;
  return (
    <>
      <MDBContainer>
        <MDBCol>
          <MDBRow className="mt-3">
            <h1>Habilidades Generales</h1>
          </MDBRow>
          <MDBTable striped>
            <MDBTableHead>
              <tr>
                <th>Nombre</th>
                <th>Significado</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {getHabilidadesTabla(habGen)}
            </MDBTableBody>
          </MDBTable>
          <MDBRow className="mt-2">
            <h1>Habilidades de investigación</h1>
          </MDBRow>
          <MDBRow className="mt-1">
            <h2>Habilidades Académicas</h2>
          </MDBRow>
          <MDBTable striped>
            <MDBTableHead>
              <tr>
                <th>Nombre</th>
                <th>Significado</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {getHabilidadesTabla(habAcad)}
            </MDBTableBody>  
          </MDBTable>
          <MDBRow className="mt-1">
            <h2>Habilidades Interpersonales</h2>
          </MDBRow>
          <MDBTable striped>
            <MDBTableHead>
              <tr>
                <th>Nombre</th>
                <th>Significado</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {getHabilidadesTabla(habInter)}
            </MDBTableBody>
          </MDBTable>
          <MDBRow className="mt-1">
            <h2>Habilidades Técnicas</h2>
          </MDBRow>
          <MDBTable striped>
            <MDBTableHead>
              <tr>
                <th>Nombre</th>
                <th>Significado</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>
              {getHabilidadesTabla(habTec)}
            </MDBTableBody>
          </MDBTable>
        </MDBCol>
      </MDBContainer>
    </>
    
  );
}

export default HabilidadesContenedor;