import React from "react";
import Login from "./components/Login";
import MainContainer from "./components/mainContainer";
import firebase from './firebase';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false
    };
  }
/* 
  checkLog = isLog => {
    if (isLog === true) {
      this.setState({ isLogged: true });
    }
  };
 */
  renderCosas = () => {
    if (!this.state.authenticated) {
      return <Login />;
      // return <Login isAuthed={this.checkLog} />;
    } else {
      return <MainContainer />;
    }
  };

 componentDidMount() {
    firebase.auth().onAuthStateChanged((authenticated) => {
      authenticated
        ? this.setState(() => ({
          authenticated: true,
        }))
        : this.setState(() => ({
          authenticated: false,
        }));
    });
  }



  render() {
    return <>{this.renderCosas()}</>;
  }
}

export default App;
