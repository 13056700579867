import React from "react";
import AgenteCard from "./Agentes/AgenteCard";
import { MDBRow, MDBCol } from "mdbreact";
import { agentes } from "../utils/datos";

const AgentesContenedor = () => {
  const AgentesCards = agentes.map(agenteCard => (
    <MDBCol
      md
      key={agenteCard.id}
      lg="3"
      className="d-flex justify-content-center "
    >
      <AgenteCard
        id={agenteCard.id}
        imagen={agenteCard.imagen}
        nombre={agenteCard.nombre}
        profesion={agenteCard.profesion}
      />
    </MDBCol>
  ));
  return (
    <>
      <MDBRow>
        <MDBCol className="text-center tituloDiario">
          <h2 className="decorado">Agentes</h2>
        </MDBCol>
      </MDBRow>
      <MDBRow className="bloques">{AgentesCards}</MDBRow>
    </>
  );
};

export default AgentesContenedor;
